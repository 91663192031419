/*jshint esversion: 6 */

import jQuery from 'jquery';
import { Collapse } from 'bootstrap';

global.jQuery = jQuery;

jQuery(($) => {
	var $win = $(window);
	var $body = $('body');

	var classes = {
		Hover       : 'hover',
		Active      : 'active',
		ShowNavMain : 'show-nav-main',
		FixedHeader : 'show-fixed-header'
	};

	var is = {
		Mobile  : false,
		Desktop : false,
		Tablet  : false
	};

	var get = {
		Scroll   : 0,
		WinWidth : 0
	};

	const collapsibleNav = new Collapse($('.navbar-collapse').get(0), { toggle: false });

	/*
		# - Ready
	*/

	addDeviceResolution();
	addBaseClickEvents();
	formShowPassword();
	smoothScrolling();
	
	/*
		# - Load
	*/

	$win.on('load', function(){
		$body.addClass(classes.PageLoad);
		onYouTubeIframeAPIReady();
		mobileProfileHover();

		if (!is.Desktop) {
			collapsibleNav.hide();
		}
		
		$body.toggleClass(classes.FixedHeader, ($win.scrollTop() > 10));
	});

	/*
		# - Scroll
	*/

	$win.on('scroll', function(){
		get.Scroll = $win.scrollTop();

		$body.toggleClass(classes.FixedHeader, (get.Scroll > 10));
	});

	/*
		# - Resize Orientationchange
	*/

	$win.on('resize orientationchange', function(){
		addDeviceResolution();
		
		if (is.Desktop) {
			collapsibleNav.show();
		}
	});

	/*
		# - Functions -
	*/

	function mobileProfileHover() {
		$('.profile').on('touchstart', function(){
			var $this = $(this);

			$this.toggleClass('hover').siblings().removeClass('hover');
		});
	}

	function onYouTubeIframeAPIReady() {
		$('.js-video').each(function() {
			var $video = $(this);
			var $btn = $video.closest('.video').find('.video__btn');
			var videoId = $video.data('videoid');
			var player = new YT.Player(this, {
				videoId: videoId,
				playerVars: {rel: 0},
				events: {
					onStateChange: function(event) {
						if (event.data == 0) {
							$('.js-video').closest('.video').removeClass('show-video');
						}
					}
				}
			});

			$btn.on('click', function(e){
				e.preventDefault();

				$(this).closest('.video').addClass("show-video");
				player.playVideo();
			});
		});
	}

	function smoothScrolling() {
		$('a[href^="#"]:not([href="#"])').on('click', function(event){
			var id = $(this).attr('href');
			var $id = $(id);
			var _offset = $id.offset().top;

			if ($id.length === 0) {
				return;
			}

			event.preventDefault();

			_offset = _offset - $('.header').height();
			$('body, html').animate({scrollTop: _offset});
		});
	}

	function formShowPassword() {
		$('.form__password').click(function(e){
			var $this = $(this);

			e.preventDefault();

			if ($this.closest('.password').find('[type="password"]').length) {
				$this.find('i').addClass('orange').closest('.password').find('input').attr('type', 'text');
			} else {
				$this.find('i').removeClass('orange').closest('.password').find('input').attr('type', 'password');
			}
		});
	}

	function addBaseClickEvents() {
		$(`.navbar-nav a[href^="${location.pathname.split("/")[1]}"]`).addClass('active');
	}

	function addDeviceResolution() {
		get.WinWidth = $win.width();

		is.Desktop = (get.WinWidth > 1024);
		is.Mobile  = (get.WinWidth <= 767);
		is.Tablet  = (get.WinWidth <= 1024 && get.WinWidth >= 768);
	}

	let forBusinessesForm = document.forms['for-business-application'];

	if (forBusinessesForm instanceof HTMLFormElement) {
		forBusinessesForm.addEventListener('submit', (event) => {
			event.preventDefault();

			let res = document.getElementById('for-business-application-result');
			let ldr = document.getElementById('for-business-application-loader');

			ldr.classList.remove('hidden');

			fetch(forBusinessesForm.action, {
				method: 'POST',
				body: new URLSearchParams(new FormData(forBusinessesForm))
			}).then((resp) => {
				return resp.json();
			}).then((body) => {
				if (body.status === 'success') {

					res.classList.remove('hidden');

					setTimeout(function() {
						res.classList.add('hidden');
						forBusinessesForm.reset();
					}, 3000);
				} else {
					throw new Error(body.message);
				}
			}).catch((error) => {
				// TODO show error mesage
			}).finally(() => {
				setTimeout(function() {
					ldr.classList.add('hidden');
				}, 2500);
			});
		});
	}

	require('ajaxchimp');

	if ($('#mc-embedded-subscribe-form').length) {
		$('#mc-embedded-subscribe-form').ajaxChimp({
			url: 'https://passen.us15.list-manage.com/subscribe/post?u=a5d6307446be85ad5d39818d2&id=ea01b2c4b3',
			callback: subscribeHandler
		});
	}
	  
	function subscribeHandler(resp) {
		const subscriptionMessage = $("#subscription-status-message");
		let message = "";

        if (resp.result === "success") {
			message = `Thank you for subscribing. 
				When we have updates we will send them to your email!`;
        } else {
            const errorMessage = resp.msg;
            
            if (
                errorMessage.includes("0 - Please enter a value") ||
                errorMessage.includes(
                    `0 - The domain portion of the email address is invalid (the portion after the @: )`
                ) ||
                errorMessage.includes(
                    `An email address must contain a single @`
                )
            ) {
                message = `Please enter a valid email address.`;
            } else if (errorMessage.includes(`1 - Please enter a value`)) {
                message = `Please enter your first name.`;
            } else if (errorMessage.includes(`is already subscribed`)) {
                message =
                    `It looks like this email is already subscribed! Stay tuned for updates.`;
            }
        }
        subscriptionMessage.fadeIn("slow");
        subscriptionMessage.text(message);
    }


	/*
	| phone number input
	*/

	const phoneInputField = document.querySelector("#phone");
	const phoneInput = window.intlTelInput(phoneInputField, {
		utilsScript:
		"https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
	});
	
	var session_token;

	/*
		# - Signup handlers
	*/
	
	console.log("hello world")
	$('#otp_input_form').hide();
	$('#user_creation_form').hide();
	$('#otp_error').hide()
	$('#phone_error').hide()

	
	$('#account_creation_result').hide()


    $('#phone_input_form').on("submit", handlePhoneSubmit);
	$('#otp_input_form').on("submit", handleOTPSubmit);
	$('#user_creation_form').on("submit", handleCreationSubmit);
	// $('otp_input_resend').click(sendOTP)


	

    function handlePhoneSubmit(event) {
        event.preventDefault();
        console.log("Phone was submitted");
		console.log(phoneInput.getNumber())
		sendOTP()

    }

	function handleOTPSubmit(event) {
		event.preventDefault();
        console.log("OTP submitted");
		verifyOTP()

	}
	

	function handleCreationSubmit(event) {
		event.preventDefault();
        console.log("User Creation Submitted");
		createAccount()
	}

	

	
	/*
		# - Signup requests
	*/
	

	function sendOTP(){
		var myHeaders = new Headers();
		myHeaders.append("x-api-key", "da2-7g3k3q5qazdxdn2znttsurss2i");
		myHeaders.append("Content-Type", "application/json");
		
		var graphql = JSON.stringify({
			query: "mutation MyMutation($phoneNumber: AWSPhone!, $source: String!) {\r\n  sendOTP(phoneNumber: $phoneNumber, source: $source) {\r\n    success\r\n  }\r\n}",
			variables: {"phoneNumber":phoneInput.getNumber(),"source":"passen.com"}
		})
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: graphql,
			redirect: 'manual'
		};
		
		fetch("https://d5wig535jjdaldjrnhct64le74.appsync-api.us-east-1.amazonaws.com/graphql", requestOptions)
			.then(response => response.json())
			.then(result =>{
				if (result['data'] == null) {
					console.log('invalid phone number')
					$('#phone_error').show()
				}else{
					$('#phone_display').html(phoneInput.getNumber())
					$('#phone_input_form').slideToggle();
					$('#otp_input_form').slideToggle();
					$('#phone_error').hide()

				}
			})
			.catch(error => console.log('error', error));
	}
	
	function verifyOTP(){
		var myHeaders = new Headers();
		myHeaders.append("x-api-key", "da2-7g3k3q5qazdxdn2znttsurss2i");
		myHeaders.append("Content-Type", "application/json");
		
		var graphql = JSON.stringify({
			query: "mutation MyMutation($otp: String!, $phoneNumber: AWSPhone!, $source: String!) {\r\n  createSessionFromOTP(phoneNumber: $phoneNumber, source: $source, otp: $otp) {\r\n    success\r\n    token\r\n  }\r\n}",
			variables: {"otp":$("#otp_input").val(),"phoneNumber":phoneInput.getNumber(),"source":"passen.com"}
		})
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: graphql,
			redirect: 'manual'
		};
		
		fetch("https://d5wig535jjdaldjrnhct64le74.appsync-api.us-east-1.amazonaws.com/graphql", requestOptions)
			.then(response => response.json())
			.then(result =>{
				// console.log()
				console.log(result)
				if (result['data']['createSessionFromOTP']['success']) {
					session_token = result['data']['createSessionFromOTP']['token']
					$('#otp_input_form').slideToggle();
					$('#user_creation_form').slideToggle();
				}else{
					$('#otp_error').show()
				}

			})
			.catch(error => console.log('error', error));
	}

	function createAccount() {
		$('#account_creation_result').show()

		var myHeaders = new Headers();
		myHeaders.append("x-api-key", "da2-7g3k3q5qazdxdn2znttsurss2i");
		myHeaders.append("Content-Type", "application/json");
		
		var graphql = JSON.stringify({
			query: "mutation MyMutation($tokenInput: SessionTokenInput!, $nameFirst: String!, $nameLast: String!, $phoneNumber: AWSPhone!) {\r\n  createScannerUser(input: {nameFirst: $nameFirst, nameLast: $nameLast, phoneNumber: $phoneNumber}, tokenInput: $tokenInput) {\r\n    id\r\n    nameFirst\r\n    phoneNumber\r\n    nameLast\r\n  }\r\n}\r\n",
			variables: {"tokenInput":{"phoneNumber":phoneInput.getNumber(),"sessionToken":session_token},"phoneNumber":phoneInput.getNumber(),"nameFirst":$("#first").val(),"nameLast":$("#last").val()}
		})
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: graphql,
			redirect: 'manual'
		};
		
		fetch("https://d5wig535jjdaldjrnhct64le74.appsync-api.us-east-1.amazonaws.com/graphql", requestOptions)
			.then(response => response.json())
			.then(result => {
				console.log(result)
				// if (result) {
					
				// }
				sendDynamicLink()
			})
			.catch(error => console.log('error', error));

	}


	function sendDynamicLink() {

		var myHeaders = new Headers();
		myHeaders.append("x-api-key", "da2-7g3k3q5qazdxdn2znttsurss2i");
		myHeaders.append("Content-Type", "application/json");

		var graphql = JSON.stringify({
			query: "mutation MyMutation($tokenInput: SessionTokenInput!, $phoneNumber: AWSPhone!) {\r\n  sendDynamicLink(phoneNumber: $phoneNumber, tokenInput: $tokenInput) {\r\n    success\r\n  }\r\n}\r\n",
			variables: {"tokenInput":{"phoneNumber":phoneInput.getNumber(),"sessionToken":session_token},"phoneNumber":phoneInput.getNumber()}
		})
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: graphql,
			redirect: 'manual'
		};

		fetch("https://d5wig535jjdaldjrnhct64le74.appsync-api.us-east-1.amazonaws.com/graphql", requestOptions)
			.then(response => response.text())
			.then(result => console.log(result))
			.catch(error => console.log('error', error));
	}

});

